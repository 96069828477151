/* General Styling for landing page */
.landing-page {
  height: 100vh;
  /* background-image: url('../../image/about1.jpg');  */
  /* Replace with your image */
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for text readability */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.text-container {
  color: #fff;
  text-align: center;
  z-index: 2;
}

.text-container h1 {
  font-size: 3rem;
  margin: 0;
}

/* About Section Styling */
.about-section {
padding-bottom: 5px;
padding-right: 24px;
padding-left: 24px;
  background-color: #f4f4f4;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  flex-wrap: wrap;
}

.about-containerrr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  flex-wrap: wrap;
  padding-left: 70px;
}
.about-text {
  flex: 1;
  padding: 20px;

  
}

.about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}


.about-imagee {
  flex: 1;
  display: flex;
  justify-content: center;
 padding-left: 20px;
 padding-right: 2px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.about-imagee img {
  max-width: 40%;
  height: auto;
  border-radius: 8px;


}

/* Team Section */
.team-section {
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;
}

.team-section h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.team-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.team-member {
  text-align: center;
  flex-basis: 45%;
  margin-bottom: 20px;
  
}

.team-member img {
  width: 100%;
  max-width: 300px;
  height: auto;
  
}

.team-member h3 {
  font-size: 1.5rem;
 
}

.teamborder{
  border-style: solid;
  border-width: 5px;
  width: 290px;
  margin-left: 144px;
}

.team-member p {
  font-size: 1.1rem;
  color: #666;
  
}

/* Mobile Responsive */
@media (max-width: 768px) {
  
  .text-container h1 {
    font-size: 2rem;
  }

  .about-container {
    flex-direction: column;
    text-align: center;
  }

  .about-text h2 {
    margin-top: -20px;
    font-size: 2rem;
  }

  .about-text p {
    font-size: 1rem;
  }

  /* .about-image {
    margin-top: 20px;
  } */

  .team-member {
    flex-basis: 100%;
  }
}




/* Contact Section Styling */
.contact-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
  margin-top:60px;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.contact-form {
  flex: 1;
  margin: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-row {
  display: flex;
  gap: 20px;
}

button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column-reverse; /* Change direction on mobile */
  }
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    text-align: center;
  }

  .contact-text iframe {
    width: 50%; /* Adjust width for iframe */
  }

  .form-row {
    flex-direction: column;
  }

  button {
    width: 100%;
  }
}


/* circle section */


.feature-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  background-color: white;
  margin-top: 30px;
}

.feature-item {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 10px; /* Space between circles */
}

.feature-circle {
  width: 120px; /* Base width for mobile */
  height: 120px; /* Base height for mobile */
  border-radius: 50%;
  background-color: #353434; /* Initial background color */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: background-color 0.3s;
  text-align: center;
}
.feature:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  background-color: #4d7544; /* Change background color on hover */
}

.feature-circle:hover {
  background-color: #334c2d; /* Hover background color */
}

.curve {
  width: 40px; /* Adjust based on desired curve size */
  height: 20px; /* Adjust based on desired curve size */
  border: solid transparent; 
  border-width: 10px 20px 0; /* Adjust these values to create the curve effect */
  border-top-color: yellow; /* Color of the curve */
  position: absolute;
  top: 50%;
  left: 100%; /* Position to the right of the circle */
  transform: translateY(-50%);
}

/* Responsive styles for larger screens */
@media (min-width: 768px) {
  .feature-circle {
      width: 150px; /* Increased width for tablet and desktop */
      height: 150px; /* Increased height for tablet and desktop */
     padding-bottom: 5px;
  }

  .feature-circle:hover {
    background-color: #334c2d; /* Hover background color */
  }
  .curve {
      width: 50px; /* Adjust based on desired curve size */
      height: 25px; /* Adjust based on desired curve size */
      border-width: 12px 25px 0; /* Adjust for larger curves */
  }
}


/* why to choose us */
.why-choose-section {
  padding: 40px 20px; /* Add padding for spacing */
  background-color: #f9f9f9; /* Light background for contrast */
  text-align: center; /* Center text */
}

.why-choose-section h2 {
  font-size: 2.5rem; /* Large heading */
  margin-bottom: 20px; /* Space below heading */
  color: #333; /* Dark color for contrast */
}

.features {
  display: flex; /* Use flexbox for layout */
  justify-content: space-around; /* Space between items */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.feature {
  background-color: #353434; /* White background for feature boxes */
  color: white;
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Inner padding */
  margin: 10px; /* Space between feature boxes */
  flex: 1; /* Flex grow to fill available space */
  min-width: 250px; /* Minimum width for responsiveness */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s; /* Transition effect for hover */
}

.feature:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}

.feature h3 {
  font-size: 1.5rem; /* Smaller heading size */
  margin-bottom: 10px; /* Space below heading */
  color:white; /* Primary color for headings */
}

.feature p {
  font-size: 1rem; /* Standard font size for text */
  color:white; /* Gray color for text */
}

/* Responsive design */
@media (max-width: 768px) {
  .why-choose-section {
    padding: 30px 10px; /* Reduced padding for smaller screens */
  }

  .why-choose-section h2 {
    font-size: 2rem; /* Smaller heading for tablets */
  }

  .feature {
    margin: 10px 0; /* Vertical margin on smaller screens */
  }
}

@media (max-width: 480px) {
  .why-choose-section h2 {
    font-size: 1.5rem; /* Smaller heading for mobile */
  }

  .feature h3 {
    font-size: 1.2rem; /* Smaller subheading for mobile */
  }
}

/* end of why to choose us  */




/* contact us  */
.contact-section {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 24px;
  padding-left: 24px;
    background-color: #f4f4f4;
  }
  
  .contact-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    gap: 10px;
    flex-wrap: wrap;
  }



  

.do_section .heading_container {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.do_section .do_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
}

.do_section .do_container .box {
  text-align: center;
  position: relative;
  z-index: 5;
  margin: 35px 25px 0 25px;
}

.do_section .do_container .box .img-box {
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #353434;
  color: white;
  border-radius: 100%;
  flex-direction: column;
  transition: background-color 0.3s;
}

.do_section .do_container .box .img-box span {
  display: block;
}

.do_section .do_container .box .img-box img {
  width: 45px;
}

.do_section .do_container .box .detail-box {
  margin-top: 35px;
}

.do_section .do_container .box:hover .img-box {
  transform: translateY(-5px); /* Lift effect on hover */
  background-color: #4d7544; /* Change background color on hover */
}





.do_section .do_container .arrow_bg::before {
  content: "";
  position: absolute;
  top: -23px;
  left: -23px;
  width: 258%;
  height: 138%;
  z-index: 3;
  background-size: cover;
}

.do_section .do_container .arrow-start::before {
  background-image: url('../../image/arrow-start.png');
}

.do_section .do_container .arrow-middle::before {
  background-image: url('../../image/arrow-middle.png');
}

.do_section .do_container .arrow-end::before {
  background-image: url('../../image/arrow-end.png');
}

@media (max-width: 768px) {
  .do_section .do_container .arrow-start::before,.do_section .do_container .arrow-middle::before,.do_section .do_container .arrow-end::before {
    width: 200px;
  }
}


.aboutt-image {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 40px;
  
}

.aboutt-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
 
}



/* our founder */
/* Founder Section Styling */
.founder-section {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.founder-heading {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
  font-family: 'Montserrat', sans-serif;
}

.founder-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap; /* For mobile responsiveness */
}

.founder-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 250px; /* Adjust card width */
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.founder-card:hover {
  transform: scale(1.05); /* Hover effect */
}

.founder-image {
  width: 100%;
  height: auto;
  /* border-radius: 50%; */
  margin-bottom: 15px;
}

.founder-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  font-family: 'Roboto', sans-serif;
}


.divider {
  display: none; /* Hidden by default */
  height: 1px;
  width: 100%;
  background-color: #ccc;
  margin: 20px 0;
}






/* Responsive styles for mobile view */
@media (max-width: 768px) {
  .divider {
    display: block; /* Show divider on mobile view */
  }
}



@media (min-width: 768px) {
  .mobileresponsive {
    display: none;
  }
}



@media (max-width: 767px) {
  .desktopresp {
    display: none;
  }
}




@media (min-width: 768px) and (max-width: 1024px) {
  .about-text iframe {
    width: 710px; /* Increase iframe width on tablet */
    height: 400px; /* Adjust height if needed */
  }
  
  .about-containerrr {
    flex-direction: column; /* Stack vertically on tablet */
  }
}



@media (max-width: 495px) {
  .framewidth{
width: 350px;
  }
}


@media (min-width: 600px) and (max-width: 650px) {
  .framewidth{
    width: 500px;
      }
}


@media (min-width: 651px) and (max-width: 690px) {
  .framewidth{
    width: 570px;
      }
}




@media (min-width: 691px) and (max-width: 770px) {
  .framewidth{
    width: 620px;
      }
}



@media (min-width: 300px) and (max-width: 359px) {
  .framewidth{
    width: 220px;
      }
}

@media (min-width: 360px) and (max-width: 380px) {
  .framewidth{
    width: 270px;
      }
}
@media (min-width: 380px) and (max-width: 420px) {
  .framewidth{
    width: 270px;
      }
}

@media (max-width: 1023px) {
  /* Apply margin-top: -80px to viewwww class in tablet and mobile views */
  .viewwww {
    margin-top: -80px;
  }
}

@media (max-width: 767px) {
  /* Apply margin-top: -80px to viewwww class in mobile view */
  .viewwww {
    margin-top: -80px;
  }
}


@media (max-width: 1023px) {
  /* Apply margin-top: -80px to viewwww class in tablet and mobile views */
  .viewwwww {
    margin-top: -60px;
  }
}

@media (max-width: 767px) {
  /* Apply margin-top: -80px to viewwww class in mobile view */
  .viewwwww {
    margin-top: -60px;
  }
}







.contactrespdesk {
  display: flex; /* Ensure it displays properly on desktop */
}

@media screen and (max-width: 1024px) {
  .contactrespdesk {
    display: none; /* Hide on tablet and smaller devices */
  }
}


.contactresp {
  display: none; /* Hidden by default */
}

@media screen and (max-width: 1024px) {
  .contactresp {
    display: flex; /* Show on tablet and mobile devices */
    flex-direction: column; /* Optional: adjust layout for smaller screens */
  }
}
