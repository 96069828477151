.landing-page {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 1s ease-in-out; /* Smooth transition over 1 second */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-container {
  color: white;
  text-align: center;
}

.text-container h1 {
  font-size: 3rem; /* Default font size */
  margin: 0;
}

/* Responsive design */
@media (max-width: 768px) {
  .text-container h1 {
    font-size: 2rem; /* Smaller font size for tablets */
  }
}

@media (max-width: 480px) {
  .text-container h1 {
    font-size: 1.5rem; /* Smaller font size for mobile */
  }
}
