.spares-services-container {
  padding: 10px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 10px auto;
  font-family: 'Arial', sans-serif;
  margin-top: 60px;
  background-image: url('../../image/bg.png'); /* Replace with your image path */
}


.services{
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 2 cards per row on large screens */
  gap: 30px; /* Space between cards */
  margin-top: 20px;

}


.spares {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 2 cards per row on large screens */
  gap: 30px; /* Space between cards */
  margin-top: 1px;

}



.service-card-header,
.spare-card-header {
  padding: 10px;
  border-radius: 5px 5px 0 0; /* Rounded top corners */
  color: #fff; /* White text for the header */
}

.service-card h3,
.spare-card h3 {
  color: #fff;
  font-size: 1.5em;
  margin: 0;
  text-align: center;
}

.service-card p,
.spare-card ul {
  color: #555;
  line-height: 1.6;
}

.spare-card ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove padding */
}

.spare-card li {
  padding: 5px 0; /* Spacing between items */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .services,
  .spares {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row on tablets */
  }

  .service-card,
  .spare-card {
    padding: 10px; /* Reduced padding for service items */
  }
}

@media (max-width: 480px) {
  .services,
  .spares {
    grid-template-columns: 1fr; /* 1 card per row on mobile */
  }



  .service-card,
  .spare-card {
    padding: 8px; /* Further reduced padding for service items */
  }
}



.former-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  flex-wrap: wrap;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  
  .text-container h1 {
    font-size: 2rem;
  }

  .former-container {
    flex-direction: column;
    text-align: center;
  }

  .about-text h2 {
    margin-top: -20px;
    font-size: 2rem;
  }

  .about-text p {
    font-size: 1rem;
  }

  /* .about-image {
    margin-top: 20px;
  } */

  .team-member {
    flex-basis: 100%;
  }
}


/* Hide the element in desktop view */
.mobileresponsiveview {
  display: block; /* Show by default */
}

@media (min-width: 1024px) {
  .mobileresponsiveview {
    display: none; /* Hide on desktop */
  }
}


/* Show the element on desktop and hide on mobile/tablet */
.desktopresponsiveview {
  display: block; /* Show by default on desktop */
}

@media (max-width: 1024px) {
  .desktopresponsiveview {
    display: none; /* Hide on mobile and tablet */
  }
}



.responsive-imagee {
  float: left;
  margin-left: -283px;
  display: block;
  
}

@media (max-width: 768px) { /* Tablet and mobile view */
  .responsive-imagee {
    margin-left: 0; /* Remove the negative margin */
    float: left; /* Optional: reset float */
  }

  .abouttt-image{
    margin-top: 4px;
  }
}

@media (max-width: 1024px) {
  .responsive-imagee {
    display: none;
  }
}


/* Show the image by default (on mobile and tablet) */
.responsive-imageeee {
  display: block; /* Ensures the image is visible by default on mobile and tablet */
}

/* Hide the image on screens larger than 1024px (desktop) */
@media (min-width: 1025px) {
  .responsive-imageeee {
    display: none;
  }
}


.coatimg{
  margin-top: -170px;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .coatimg {
    margin-top: 0px; /* Apply 300px margin-top for mobile view */
  }
}








body {
  font-family: 'Montserrat', sans-serif;
 
}


/* Full-screen container with background image */
.details-container {
  width: 100%;
  height: auto;
  background-image: url('../../image/bagg.png'); /* Replace with your image URL */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

/* Content wrapper to align columns */
.details-content {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: auto;
margin-top: 100px;
}

/* Left and Right columns */
.column {
  flex: 1;
  border-radius: 10px;
}

/* Left column styling */
.left {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.left img {
  width: 100%; /* Increase the width to occupy more space */
  max-width: 500px; /* Allow a larger maximum size for the image */
  height: auto;
  margin-bottom: 15px;

}

.left-content {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  padding: 5px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
}


/* Right column styling */
.right {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
  padding: 5px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 10px;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .details-content {
    flex-direction: column;
    width: 95%;
    height: auto;
  }

  .column {
    margin: 10px 0;
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .details-container {
    margin-top: -20px;
    height: auto;
  }

  .column-right{
    margin-top: -50px;
  }

  .details-content {
    flex-direction: column;
  }

  .column {
    padding: 10px;
  }

  .mobileresponsiveview {
    font-size: 30px; /* Font size for tablet screens */
  }
}



/* Hide the element in desktop view */
.mobileresponsiveview {
  display: block; /* Show by default */
}

@media (min-width: 1024px) {
  .mobileresponsiveview {
    display: none; /* Hide on desktop */
   
  }
  .mobileresponsiveview {
    font-size: 45px; /* Font size for tablet screens */
  }
}


/* Show the element on desktop and hide on mobile/tablet */
.desktopresponsiveview {
  display: block; /* Show by default on desktop */
}

@media (max-width: 1024px) {
  .desktopresponsiveview {
    display: none; /* Hide on mobile and tablet */
  }
}


/* Increase font size for tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .mobileresponsiveview {
    font-size: 45px; /* Font size for tablet screens */
  }
}







/* Responsive image styling */
.responsive-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
}

/* Desktop view: Show desktop content and hide mobile-tablet content */
.desktop-only {
  display: block;
}

.mobile-tablet-only {
  display: none;
}

/* Mobile and Tablet view: Hide desktop content and show mobile-tablet content */
@media (max-width: 1024px) {
  .desktop-only {
    display: none; /* Hide desktop content */
  }

  .mobile-tablet-only {
    display: block; /* Show mobile and tablet content */
  }
}





/* Default: Show the image on all screens */
.hidedesk {
  display: block;
}

/* Hide the image on desktop screens (min-width: 992px) */
@media (min-width: 992px) {
  .hidedesk {
    display: none;
  }
}