.carousel-container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.gallery-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}

.carousel__track {
  display: flex;
  overflow: hidden;
  justify-content: center;
  width: 100%;
}

.carousel__image {
  width: 550px;
  height: 200px;
  object-fit: cover;
  border-radius: 25px;
  border: 3px solid lightgray;
  margin: 0 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.carousel__image:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.carousel__arrow {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 35px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.carousel__arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Responsive Design */
@media (min-width: 1024px) {
  .carousel__image {
    width: 300px;
    height: 200px;
  }

  /* Reduce space between the button and images for desktop view */
  .carousel__arrow {
    margin: 0 5px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .carousel__image {
    width: 450px;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .carousel__image {
    width: 100%;
    height: auto;
  }
  .carousel__arrow {
    font-size: 25px;
    width: 40px;
    height: 40px;
  }
}
