.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 24px;
  box-sizing: border-box; /* Ensures padding doesn't break layout */
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left .logo {
  height: 50px;
}

.navbar-right {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 30px;
}

.nav-menu {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-menu li {
  margin: 0 15px;
  color: black;
  cursor: pointer;
  position: relative;
}

.nav-menu li:hover {
  text-decoration: underline;
}

.dropdown-menu {
  width: 250px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f0f1f0;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-menu li {
  font-size: 18px;
  padding: 8px 12px;
  color: black;
}

.dropdown-menu li:hover {
    background-color: white; /* Hover color for dropdown */
}

.hamburger {
  display: none;
  font-size: 24px;
  color: black;
  background: none;
  border: none;
  cursor: pointer;
  text-align: right;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .navbar {
    padding: 10px 15px;
  }

  .nav-menu li {
    font-size: 20px;
    margin: 0 10px;
  }

  .dropdown-menu {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px 10px;
    flex-direction: row;
    align-items: center;

  }

  .navbar-right {
    width: 100%;
    justify-content: flex-end;
  }

  .nav-menu {
    flex-direction: column;
    width: 100%;
    display: none;
    background-color: white;
    position: absolute;
    top: 20px;
    left: 0;
    right: 10px; /* Reduce the right side space */
    padding: 15px;
    padding-right: 20px; /* Add extra space on the right */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
  }

  .nav-menu.open {
    display: flex;
  
  }

  .dropdown-menu {
    width: 100%;
    font-size: 7px;
    height: 245px;
    overflow-y: scroll;
   
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .nav-menu li {
    margin: 10px 0;
  
  }

  .hamburger {
    display: block;
  }

  .dropdown-menu li:hover {
    background-color: white; /* Hover color for dropdown */
}
}



