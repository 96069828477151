.contact-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Left Image Column */
  .contact-image {
    flex: 1 1 50%;
    padding: 20px;
  }
  
  .contact-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }
  
  /* Right Iframe Column */
  .contact-form {
    flex: 1 1 50%;
    padding: 20px;
  }
  
  .contact-form iframe {
    width: 100%;
    height: 500px;
    border: 0;
    border-radius: 10px;
  }
  
  /* Tablet and Mobile view */
  @media (max-width: 1024px) {
    .contact-section {
      flex-direction: column;
    }
  
    .contact-image, .contact-form {
      flex: 1 1 100%;
      padding: 10px;
    }
  
    .contact-form iframe {
      height: 400px;
    }
  }
  
  @media (max-width: 767px) {
    .contact-form iframe {
      height: 300px;
    }
  }
  