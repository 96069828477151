body {
  font-family: 'Montserrat', sans-serif;
 
}


/* Full-screen container with background image */
.details-container {
  width: 100%;
  height: auto;
  background-image: url('../../image/bagg.png'); /* Replace with your image URL */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

/* Content wrapper to align columns */
.details-content {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: auto;
margin-top: 100px;
}

/* Left and Right columns */
.column {
  flex: 1;
  border-radius: 10px;
}

/* Left column styling */
.left {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.left img {
  width: 100%; /* Increase the width to occupy more space */
  max-width: 500px; /* Allow a larger maximum size for the image */
  height: auto;
  margin-bottom: 15px;

}

.left-content {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  text-align: left;
  padding: 5px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
}


/* Right column styling */
.right {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
  padding: 5px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 10px;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .details-content {
    flex-direction: column;
    width: 95%;
    height: auto;
  }

  .column {
    margin: 10px 0;
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .details-container {
    margin-top: -20px;
    height: auto;
  }

  .column-right{
    margin-top: -90px;
  }

  .details-content {
    flex-direction: column;
  }

  .column {
    padding: 10px;
  }

  .mobileresponsiveview {
    font-size: 30px; /* Font size for tablet screens */
  }
}



/* Hide the element in desktop view */
.mobileresponsiveview {
  display: block; /* Show by default */
}

@media (min-width: 1024px) {
  .mobileresponsiveview {
    display: none; /* Hide on desktop */
   
  }
  .mobileresponsiveview {
    font-size: 45px; /* Font size for tablet screens */
  }
}


/* Show the element on desktop and hide on mobile/tablet */
.desktopresponsiveview {
  display: block; /* Show by default on desktop */
}

@media (max-width: 1024px) {
  .desktopresponsiveview {
    display: none; /* Hide on mobile and tablet */
  }
}


/* Increase font size for tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .mobileresponsiveview {
    font-size: 45px; /* Font size for tablet screens */
  }
}







/* Responsive image styling */
.responsive-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
}

/* Desktop view: Show desktop content and hide mobile-tablet content */
.desktop-only {
  display: block;
}

.mobile-tablet-only {
  display: none;
}

/* Mobile and Tablet view: Hide desktop content and show mobile-tablet content */
@media (max-width: 1024px) {
  .desktop-only {
    display: none; /* Hide desktop content */
  }

  .mobile-tablet-only {
    display: block; /* Show mobile and tablet content */
  }
}




