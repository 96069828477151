body {
  font-family: 'Montserrat', sans-serif;
 
}
/* Container Styles */
.spare-container {
  width: 100%;
  height: auto;
  background-image: url('../../image/bagg.png'); /* Replace with your image URL */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-attachment: fixed;
  margin: 0;
  box-sizing: border-box;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 60px;
  padding-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
}

/* Content Styles */
.spare-content {
  font-family: 'Montserrat', sans-serif;
  padding: 20px;
 
 margin-top: 40px;
  
  width: 100%;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  

  .spare-container {
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 60px;
    padding-bottom: 30px;
  }

  .spare-content {
    padding: 15px;
  }
}



 
.services{
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 2 cards per row on large screens */
  gap: 30px; /* Space between cards */
  margin-top: 20px;

}


.spares {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 2 cards per row on large screens */
  gap: 30px; /* Space between cards */
  margin-top: 1px;

}

.service-card,
.spare-card {
  background-color: #ffffff;
  border: 2px solid transparent;
 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth hover effect */
  position: relative; /* Position relative for pseudo-elements */

}

.service-card:hover,
.spare-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

.service-card-header,
.spare-card-header {
  padding: 10px;
  border-radius: 5px 5px 0 0; /* Rounded top corners */
  color: #fff; /* White text for the header */
}

.service-card h3,
.spare-card h3 {
  color: #fff;
  font-size: 1.5em;
  margin: 0;
  text-align: center;
}

.service-card p,
.spare-card ul {
  color: #555;
  line-height: 1.6;
}

.spare-card ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove padding */
}

.spare-card li {
  padding: 5px 0; /* Spacing between items */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .services,
  .spares {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row on tablets */
  }

  .service-card,
  .spare-card {
    padding: 10px; /* Reduced padding for service items */
  }
}

@media (max-width: 480px) {
  .services,
  .spares {
    grid-template-columns: 1fr; /* 1 card per row on mobile */
  }



  .service-card,
  .spare-card {
    padding: 8px; /* Further reduced padding for service items */
  }
}


/* Contact Us Section Styles */
.contact-us-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  color: #333;
  text-align: center;
  font-family: 'Arial', sans-serif;
  /* border-top: 4px solid #1abc9c; */
}

.contact-us-section h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.contact-text {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.6;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.contact-details {
  font-size: 1rem;
  line-height: 1.6;
}

.contact-details p {
  margin: 10px 0;
}

.contact-details a {
  color: #1abc9c;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.contact-details a:hover {
  color: #16a085;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-us-section {
    padding: 20px 10px;
  }

  .contact-us-section h2 {
    font-size: 1.5rem;
  }

  .contact-text {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .contact-details {
    font-size: 0.9rem;
  }
}
