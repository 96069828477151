
.about-section {
    padding-top: 90px;
    padding-bottom: 5px;
    padding-right: 24px;
    padding-left: 24px;
    background-color:white;
    font-family: 'Montserrat', sans-serif;
  }
  
  .about-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    gap: 10px;
    flex-wrap: wrap;
    font-family: 'Montserrat', sans-serif;
    
  }
  
  .about-text {
    flex: 1;
    position: relative;
    /* background-image: url('../../image/dot.jpg');  */
    background-size: cover; /* Cover the entire section */
    background-position: center; /* Center the image */
    color: white; /* Change text color to white for better visibility */
    padding: 40px; /* Add padding for better text placement */
    text-align: center; /* Center the text */
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .about-text h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    z-index: 1; /* Bring text above the background */
    font-family: 'Montserrat', sans-serif;
  }
  
  .about-text p {
    font-size: 1rem;
    line-height: 1.5;
    z-index: 1; /* Bring text above the background */
    font-family: 'Montserrat', sans-serif;
  }
  
  .about-imagee {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .about-imagee img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  


/* Base style for the heading */
.details-headingg {
  color: #4d7544;
  margin-top: -270px; /* Default for larger screens */
}

/* Responsive margin adjustments for tablets */
@media (max-width: 768px) {
  .details-heading {
    margin-top: -50px; /* Adjust for tablets */
  }

 
}

/* Responsive margin adjustments for mobile screens */
@media (max-width: 480px) {
  .details-heading {
    margin-top: 0; /* Adjust for small mobile screens */
  }

}



/* Default styling for the container */
.image-container {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* space between images */
  flex-wrap: wrap; /* Ensure images wrap on smaller screens */
  padding: 20px;
}

/* Styling for each image item */
.image-item {
  flex: 1;
  max-width: 48%; /* Take up 48% of the width on desktop */
  height: auto;
}

/* Ensure images are responsive */
.responsive-image {
  width: 100%;
  height: auto;
}

/* Tablet view (up to 768px) */
@media (max-width: 768px) {
  .image-item {
    max-width: 48%; /* Take up 48% of the width on tablet */
  }
 
}

/* Mobile view (up to 480px) */
@media (max-width: 480px) {
  .image-item {
    max-width: 100%; /* Stack images on top of each other */
  }
  .image-container {
    flex-direction: column; /* Stack images vertically on mobile */
    padding: 10px; /* Optional: less padding on mobile */
  }
}




/* Base styles for the desktop view */
.desktopview {
    display: block; /* Show by default */
  }
  
  /* Base styles for the mobile/tablet view */
  .mobileview {
    display: block; /* Show by default */
  }
  
  /* Media query for mobile/tablet screens */
  @media (max-width: 768px) {
    .desktopview {
      display: none; /* Hide on mobile and tablet */
    }
    .mobileview {
      display: block; /* Show on mobile/tablet */
    }
  }
  
  /* Media query for desktop screens */
  @media (min-width: 1024px) {
    .desktopview {
      display: block; /* Show on desktop */
    }
    .mobileview {
      display: none; /* Hide on desktop */
    }
  }
  

/* Hide on mobile and tablet */
.formerdesktop {
  display: none; /* Hidden by default */
}

/* Show only on desktop (screens wider than 1024px) */
@media (min-width: 1024px) {
  .formerdesktop {
    display: block; /* Visible on desktop */
  }
}

  
.details-container {
  width: 100%;
  height: auto;
  background-image: url('../../image/bagg.png'); /* Replace with your image URL */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
}



.pressure-former-container {
  width: 100%;
  height: auto;
  background-image: url('../../image/bagg.png'); /* Replace with your image URL */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}



.hideindesktop {
  display: block; /* Default: Visible on smaller screens */
}

@media screen and (min-width: 1025px) {
  .hideindesktop {
    display: none; /* Hide on desktop screens (width 1025px and larger) */
  }
}