body {
    font-family: 'Montserrat', sans-serif;
   
  }

/* Global footer styles */
.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background-color: #4d7544;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.footer-section {
    flex: 1;
    min-width: 250px; /* Minimum width for responsive stacking */
    margin: 10px;
    font-family: 'Montserrat', sans-serif;
}

/* Heading Styles */
.footer h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
}

/* Text and List Styles */
.footer p, .footer ul {
    margin: 0;
    line-height: 1.6;
    font-family: 'Montserrat', sans-serif;
}

.footer ul {
    list-style: none;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

.footer ul li {
    cursor: pointer;
    margin-bottom: 8px;
    font-family: 'Montserrat', sans-serif;
}

/* Copyright Section */
.footer-copyright {
    text-align: center;
    margin-top: 20px;
    width: 100%; /* Ensures full width at the bottom */
    padding-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    font-family: 'Montserrat', sans-serif;
}

/* Responsive for tablet and smaller screens */
@media (max-width: 1024px) {
    .footer-section {
        margin: 15px 0;
        text-align: center;
        width: 100%;
        font-family: 'Montserrat', sans-serif;
    }
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column; /* Stack sections vertically */
        align-items: center;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }

    .footer-section {
        margin: 10px 0; /* More spacing between stacked sections */
        width: 100%; /* Full width for each section */
        font-family: 'Montserrat', sans-serif;
    }

    .footer ul {
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 15px;
        font-family: 'Montserrat', sans-serif;
    }

    .footer h3 {
        font-size: 1rem;
        font-family: 'Montserrat', sans-serif;
    }

    .footer-section p, .footer ul li {
        font-size: 0.9rem;
        font-family: 'Montserrat', sans-serif;
    }

    .footer ul li {
        margin-bottom: 6px;
        font-family: 'Montserrat', sans-serif;
    }

    .footer-copyright {
        font-size: 0.8rem;
        font-family: 'Montserrat', sans-serif;
    }
}
